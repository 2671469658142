// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import StateMachine from 'javascript-state-machine';
import { parseISODate } from '@/datetime/dateTime.utilities';
import { handleInvestigateRangeUpdate } from '@/scatterPlot/scatterPlot.actions';
import { count as pendingRequestCount } from '@/requests/pendingRequests.utilities';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import { sqDurationStore, sqTrendStore, sqWorksheetStore } from '@/core/core.stores';
import { AUTO_UPDATE, DEFAULT_DISPLAY_RANGE_DURATION_DAYS } from '@/trendData/trendData.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { fetchAllItems, fetchAllTimebarCapsules, fetchTableAndChartCapsules } from '@/trendData/trend.actions';
import { DEBOUNCE } from '@/core/core.constants';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';

export const ENTRY_STATE_MACHINE_TIMEOUT = 30 * 1000;

let debouncedOnDurationChange, debouncedOnInvestigateChange;
let isAutoUpdate, autoUpdateHeartbeat, isAutoUpdateInit;

/**
 * FSM (finite state machine) to "remember" what individual date/duration fields the user has last modified, in
 * order to make an informed decision about which of the other two fields to modify. For example, if the user
 * enters a new start date then changes the duration, they probably expect that the end date is adjusted.
 * The FSM is not used if both start and end fields are modified simultaneously (e.g. step forward/back,
 * drag region, etc.) Display Range and Investigate Range have separate FSMs but the same behavior.
 * When no fields are individually modified in 30 seconds, the state machine resets to its default state.
 */
const FSM = {
  displayRange: createFSM('displayRange'),
  investigateRange: createFSM('investigateRange'),
  timeouts: {
    displayRange: undefined,
    investigateRange: undefined,
    timerLength: ENTRY_STATE_MACHINE_TIMEOUT, // 30 second timeout to reset state machine
  },
};

export const autoUpdate = {
  initialize: autoUpdateInitialize,
  setMode: autoUpdateSetMode,
  setManualInterval: autoUpdateSetManualInterval,
  allowed: autoUpdateAllowed,
};
export const displayRange = {
  updateDuration: FSM.displayRange.setDuration.bind(FSM.displayRange), // @param {moment.duration} New duration
  updateStart: FSM.displayRange.setStart.bind(FSM.displayRange), // @param {Number|moment} New start time
  updateEnd: FSM.displayRange.setEnd.bind(FSM.displayRange), // @param {Number|moment} New end time
  updateTimes: displayRangeUpdateTimes,
  resetTimes: displayRangeResetTimes,
  copyFromInvestigateRange: displayRangeCopyFromInvestigateRange,
  stepBackHalf: _.partial(displayRangeStepByDuration, -0.5),
  stepBack: _.partial(displayRangeStepByDuration, -1.0),
  stepForward: _.partial(displayRangeStepByDuration, 1.0),
  stepForwardHalf: _.partial(displayRangeStepByDuration, 0.5),
  stepToEnd: displayRangeStepToEnd,
  setParamsInStore: displayRangeSetParamsInStore,
};
export const investigateRange = {
  updateDuration: FSM.investigateRange.setDuration.bind(FSM.investigateRange), // {moment.duration} New duration
  updateStart: FSM.investigateRange.setStart.bind(FSM.investigateRange), // @param {Number|moment} New start time
  updateEnd: FSM.investigateRange.setEnd.bind(FSM.investigateRange), // @param {Number|moment} New end time
  updateTimes: investigateRangeUpdateTimes,
  copyFromDisplayRange: investigateRangeCopyFromDisplayRange,
};

const exposedExports = {
  investigateRange,
  displayRange,
  autoUpdate,
};

/************************************************ Auto Update ****************************************************/

/**
 * Initializes auto update and starts the auto update heartbeat interval timer.
 */
export function autoUpdateInitialize() {
  let lastInterval;
  let next = moment().utc().valueOf() + sqDurationStore.autoUpdate.interval;

  autoUpdate(true);
  clearTimeout(autoUpdateHeartbeat);
  autoUpdateHeartbeat = setInterval(_.ary(autoUpdate, 0), 1000, false);

  /**
   * Auto update worker function that is called duration initialization and by the $interval timer.
   *
   * @param isInit - If true and auto update is allowed and enabled then now is immediately updated
   * If falsy, then now is updated if auto update is allowed, enabled, and an update is required.
   */
  function autoUpdate(isInit: boolean) {
    let now, updateNeeded;
    let interval, intervalChanged;
    const isCapsuleTime = sqTrendStore.isTrendViewCapsuleTime();

    now = moment().utc().valueOf();
    interval = sqDurationStore.autoUpdate.interval;

    intervalChanged = interval !== lastInterval;
    lastInterval = interval;

    // If the interval was changed, then use it to compute the next update time. Otherwise,
    // delay the start of next update interval until after all async operations have completed.
    if (intervalChanged || pendingRequestCount() !== 0) {
      next = now + interval;
    }

    updateNeeded = now >= next;

    if (autoUpdateAllowed() && sqDurationStore.autoUpdate.mode !== AUTO_UPDATE.MODES.OFF && (isInit || updateNeeded)) {
      try {
        isAutoUpdate = true;
        isAutoUpdateInit = isInit;
        flux.dispatch('AUTO_UPDATE_SET_NOW', { now }, PUSH_IGNORE);
        if (!isCapsuleTime) {
          displayRange.updateEnd(moment.utc(now + sqDurationStore.autoUpdate.offset));
        } else {
          displayRange.updateTimes(
            moment.utc(sqDurationStore.displayRange.start),
            moment.utc(now + sqDurationStore.autoUpdate.offset),
          );
        }

        next = now + interval;
      } finally {
        isAutoUpdate = false;
        isAutoUpdateInit = false;
      }
    }
  }
}

/**
 * Determines if auto update is allowed. It is not allowed in some worksheet modes, such as capsule time and
 * scatter plot, because the charts are not set up to handle it. It is also not allowed when capturing a screenshot
 * since that system needs to use the exact time range in the store, not a calculated one, so that httpCache works.
 *
 * @returns {Boolean} - true if allowed, false otherwise
 */
export function autoUpdateAllowed() {
  const allowedViews = [WORKSHEET_VIEW.TREEMAP, WORKSHEET_VIEW.TREND, WORKSHEET_VIEW.TABLE];
  return !headlessRenderMode() && _.includes(allowedViews, sqWorksheetStore.view.key);
}

/**
 * Sets the auto update mode
 *
 * @param {String} mode - one of AUTO_UPDATE.MODES (either AUTO, MANUAL or OFF)
 */
export function autoUpdateSetMode(mode) {
  flux.dispatch('AUTO_UPDATE_SET_MODE', { mode });
}

/**
 * Commands the duration store to record the current auto update offset (i.e. the number of milliseconds between
 * now and the display range end.
 */
export function autoUpdateComputeOffset() {
  flux.dispatch('AUTO_UPDATE_COMPUTE_OFFSET');
}

/**
 * Sets the manual update interval
 *
 * @param {Object} manualInterval - object container for manual interval properties
 * @param {Number} manualInterval.value - numeric value of interval
 * @param {string} manualInterval.units - string value of units
 */
export function autoUpdateSetManualInterval(manualInterval) {
  flux.dispatch('AUTO_UPDATE_SET_MANUAL_INTERVAL', { manualInterval });
}

/************************************************ Finite State Machine *******************************************/

/**
 * Create a finite state machine for either the display or investigate range. The FSM determines which field to
 * modify when only one of the three fields - start, duration, end - is changed.
 *
 * @param {String} rangeType - Range to control, 'displayRange' or 'investigateRange'
 * @return {Object} StateMachine FSM object
 */
export function createFSM(rangeType) {
  return StateMachine.create({
    initial: 'none',
    sameStateTransitions: true,
    events: generateEvents(),
    callbacks: generateCallbacks(rangeType),
  });
}

/**
 * Create FSM callbacks for the specified range, either display or investigate.
 *
 * @param {String} rangeType - Range to control, 'displayRange' or 'investigateRange'
 * @return {Object} Object containing all FSM state callbacks
 */
export function generateCallbacks(rangeType) {
  return {
    // These callback names are automatically generated by the library based on the state names
    onstartThenEnd: _.partialRight(setEndFixStart, rangeType),
    onstartThenDuration: _.partialRight(setDurationFixStart, rangeType),
    onendThenStart: _.partialRight(setStartFixEnd, rangeType),
    onendThenDuration: _.partialRight(setDurationFixEnd, rangeType),
    ondurationThenStart: _.partialRight(setStartFixDuration, rangeType),
    ondurationThenEnd: _.partialRight(setEndFixDuration, rangeType),
  };
}

/**
 * Create array of all FSM states and allowed transitions.
 *
 * @return {Object[]} FSM state transition
 */
export function generateEvents() {
  return [
    {
      name: 'reset',
      from: '*',
      to: 'none',
    },
    {
      name: 'setStart',
      from: ['none', 'startThenDuration', 'endThenDuration', 'durationThenStart'],
      to: 'durationThenStart',
    },
    {
      name: 'setStart',
      from: ['startThenEnd', 'endThenStart', 'durationThenEnd'],
      to: 'endThenStart',
    },
    {
      name: 'setEnd',
      from: ['none', 'startThenDuration', 'endThenDuration', 'durationThenEnd'],
      to: 'durationThenEnd',
    },
    {
      name: 'setEnd',
      from: ['startThenEnd', 'durationThenStart', 'endThenStart'],
      to: 'startThenEnd',
    },
    {
      name: 'setDuration',
      from: ['none', 'startThenEnd', 'endThenDuration', 'durationThenEnd'],
      to: 'endThenDuration',
    },
    {
      name: 'setDuration',
      from: ['endThenStart', 'durationThenStart', 'startThenDuration'],
      to: 'startThenDuration',
    },
  ];
}

/**
 * Resets both FSMs to their default state
 */
export function cleanup() {
  _.forEach(['displayRange', 'investigateRange'], function (rangeType) {
    clearTimeout(FSM.timeouts[rangeType]);
    FSM[rangeType].reset.call(FSM[rangeType]);
  });

  clearInterval(autoUpdateHeartbeat);
}

/**
 * Sets the end time of a range, keeping the start time fixed.
 * This is a callback for the FSM.
 *
 * @param {String} event - Name of event triggered
 * @param {String} from - Name of state transitioning from
 * @param {String} to - Name of state transitioning to
 * @param {Moment} newEnd - New end date for the range
 * @param {String} rangeType - Range type, 'displayRange' or 'investigateRange'
 */
export function setEndFixStart(event, from, to, newEnd, rangeType) {
  const start = sqDurationStore[rangeType].start;
  const duration = sqDurationStore[rangeType].duration;
  if (newEnd.isBefore(start)) {
    exposedExports[rangeType].updateTimes(moment.utc(newEnd).subtract(duration), newEnd);
  } else {
    exposedExports[rangeType].updateTimes(start, newEnd);
  }

  startFSMTimeout(rangeType);
}

/**
 * Sets the duration length of a range, keeping the start time fixed.
 * This is a callback for the FSM.
 *
 * @param {String} event - Name of event triggered
 * @param {String} from - Name of state transitioning from
 * @param {String} to - Name of state transitioning to
 * @param {Moment} newDuration - New duration for the range
 * @param {String} rangeType - Range type, 'displayRange' or 'investigateRange'
 */
export function setDurationFixStart(event, from, to, newDuration, rangeType) {
  const start = sqDurationStore[rangeType].start;
  exposedExports[rangeType].updateTimes(start, moment.utc(start).add(newDuration));
  startFSMTimeout(rangeType);
}

/**
 * Sets the start time of a range, keeping the end time fixed.
 * This is a callback for the FSM.
 *
 * @param {String} event - Name of event triggered
 * @param {String} from - Name of state transitioning from
 * @param {String} to - Name of state transitioning to
 * @param {Moment} newStart - New start date for the range
 * @param {String} rangeType - Range type, 'displayRange' or 'investigateRange'
 */
export function setStartFixEnd(event, from, to, newStart, rangeType) {
  const end = sqDurationStore[rangeType].end;
  const duration = sqDurationStore[rangeType].duration;
  if (end.isBefore(newStart)) {
    exposedExports[rangeType].updateTimes(newStart, moment.utc(newStart).add(duration));
  } else {
    exposedExports[rangeType].updateTimes(newStart, end);
  }

  startFSMTimeout(rangeType);
}

/**
 * Sets the duration length of a range, keeping the end time fixed.
 * This is a callback for the FSM.
 *
 * @param {String} event - Name of event triggered
 * @param {String} from - Name of state transitioning from
 * @param {String} to - Name of state transitioning to
 * @param {Moment} newDuration - New duration length for the range
 * @param {String} rangeType - Range type, 'displayRange' or 'investigateRange'
 */
export function setDurationFixEnd(event, from, to, newDuration, rangeType) {
  const end = sqDurationStore[rangeType].end;
  exposedExports[rangeType].updateTimes(moment.utc(end).subtract(newDuration), end);
  startFSMTimeout(rangeType);
}

/**
 * Sets the start time of a range, keeping the duration length fixed.
 * This is a callback for the FSM.
 *
 * @param {String} event - Name of event triggered
 * @param {String} from - Name of state transitioning from
 * @param {String} to - Name of state transitioning to
 * @param {Moment} newStart - New start date for the range
 * @param {String} rangeType - Range type, 'displayRange' or 'investigateRange'
 */
export function setStartFixDuration(event, from, to, newStart, rangeType) {
  const duration = sqDurationStore[rangeType].duration;
  exposedExports[rangeType].updateTimes(newStart, moment.utc(newStart).add(duration));
  startFSMTimeout(rangeType);
}

/**
 * Sets the end time of a range, keeping the duration length fixed.
 * This is a callback for the FSM.
 *
 * @param {String} event - Name of event triggered
 * @param {String} from - Name of state transitioning from
 * @param {String} to - Name of state transitioning to
 * @param {Moment} newEnd - New end date for the range
 * @param {String} rangeType - Range type, 'displayRange' or 'investigateRange'
 */
export function setEndFixDuration(event, from, to, newEnd, rangeType) {
  const duration = sqDurationStore[rangeType].duration;
  const option = isAutoUpdate ? PUSH_IGNORE : undefined;
  exposedExports[rangeType].updateTimes(moment.utc(newEnd).subtract(duration), newEnd, option);
  startFSMTimeout(rangeType);
}

/**
 * Resets to the FSM to initial conditions.
 *
 * @param {String} rangeType - Range type, 'displayRange' or 'investigateRange'
 */
export function startFSMTimeout(rangeType) {
  clearTimeout(FSM.timeouts[rangeType]);
  FSM.timeouts[rangeType] = setTimeout(FSM[rangeType].reset.bind(FSM[rangeType]), FSM.timeouts.timerLength);
}

/************************************************ Display Range ************************************************/

/**
 * Updates the display range start and end times. Duration is automatically adjusted to match the new range.
 * If the start time is after the end time, the values are rejected and no change is made.
 * @param {Number} start - New start time
 * @param {Number} end   - New end time
 * @param {String} [option] - sqStateSynchronizer option (e.g. PUSH_IGNORE) that modifies workstep push behavior.
 */
export function displayRangeUpdateTimes(start: number | moment.Moment, end: number | moment.Moment, option?) {
  const startMoment = moment.utc(start);
  const endMoment = moment.utc(end);
  if (startMoment >= endMoment) {
    return;
  }

  dispatchUpdateDisplayRangeTimes(startMoment, endMoment, false, option);
}

/**
 * Resets the display range times to a default. Default is a time range {@link DEFAULT_DISPLAY_RANGE_DURATION_DAYS}
 * days long ending at the current time.
 */
export function displayRangeResetTimes() {
  const endMoment = moment.utc();
  const startMoment = moment.utc(endMoment).subtract(DEFAULT_DISPLAY_RANGE_DURATION_DAYS, 'days');
  dispatchUpdateDisplayRangeTimes(startMoment, endMoment, false);
}

/**
 * Sets the display range start and end time by adding a multiple of the current duration to both the start and end.
 * The duration is unchanged.
 *
 * @param {Number} multiple - Multiple of the current duration to use as the offset, positive to step forward or
 *                          negative to step backward.
 */
export function displayRangeStepByDuration(multiple) {
  const stepInterval = sqDurationStore.displayRange.duration.asMilliseconds() * multiple;
  const endMoment = moment.utc(sqDurationStore.displayRange.end).add(stepInterval, 'ms');
  const startMoment = moment.utc(sqDurationStore.displayRange.start).add(stepInterval, 'ms');
  const oldInvestigateStartTime = sqDurationStore.investigateRange.start;
  const oldInvestigateEndTime = sqDurationStore.investigateRange.end;
  dispatchUpdateDisplayRangeTimes(startMoment, endMoment, false);

  if (endMoment > oldInvestigateEndTime) {
    investigateRange.updateTimes(
      moment.utc(endMoment.valueOf() - (oldInvestigateEndTime.valueOf() - oldInvestigateStartTime.valueOf())),
      endMoment,
    );
  } else if (startMoment < oldInvestigateStartTime) {
    investigateRange.updateTimes(
      startMoment,
      moment.utc(startMoment.valueOf() + (oldInvestigateEndTime.valueOf() - oldInvestigateStartTime.valueOf())),
    );
  }
}

/**
 * Sets the display range end time to 'now'. The display and investigate duration is unchanged.
 */
export function displayRangeStepToEnd() {
  const duration = sqDurationStore.displayRange.duration;
  const now = moment().utc().valueOf();
  flux.dispatch('AUTO_UPDATE_CLEAR_OFFSET');
  flux.dispatch('AUTO_UPDATE_SET_NOW', { now }, PUSH_IGNORE);

  if (sqTrendStore.isTrendViewCapsuleTime()) {
    flux.dispatch('TREND_SET_CAPSULE_TIME_OFFSETS', { lower: 0, upper: 0 }, PUSH_IGNORE);
  }

  dispatchUpdateDisplayRangeTimes(
    moment.utc(now + sqDurationStore.autoUpdate.offset).subtract(duration),
    moment.utc(now + sqDurationStore.autoUpdate.offset),
    true,
  );
}

/**
 * Sets the display range with the start/end times from the investigate range
 */
export function displayRangeCopyFromInvestigateRange() {
  displayRange.updateTimes(
    moment.utc(sqDurationStore.investigateRange.start),
    moment.utc(sqDurationStore.investigateRange.end),
  );
}

/**
 * Dispatch the display range update times change and re-request series data if they have changed
 * @param {Moment} start - New start time as a Moment.
 * @param {Moment} end - New end time as a Moment.
 * @param {Boolean} keepInvestigateDuration - True if the investigate Range duration should be held
 * @param {String} [option] - sqStateSynchronizer option (e.g. PUSH_IGNORE) that modifies workstep push behavior.
 * @param {Boolean} [skipInvestigateCheck=false] - True to skip checking if the investigate range should be expanded
 */
export function dispatchUpdateDisplayRangeTimes(
  start,
  end,
  keepInvestigateDuration?,
  option?,
  skipInvestigateCheck = false,
) {
  const maintainInvestigateDuration = keepInvestigateDuration || isAutoUpdate;
  if (!start.isSame(sqDurationStore.displayRange.start) || !end.isSame(sqDurationStore.displayRange.end)) {
    // Auto updates should not create worksteps
    if (isAutoUpdate) {
      option = PUSH_IGNORE;
    }

    flux.dispatch('UPDATE_DISPLAY_RANGE_TIMES', { start, end }, option);

    if (!skipInvestigateCheck) {
      if (maintainInvestigateDuration) {
        shiftInvestigateRangeIfRequired();
      } else {
        expandInvestigateRangeIfRequired();
      }
    }

    // If not an auto update, then trigger computation of the auto update offset
    if (!isAutoUpdate) {
      autoUpdateComputeOffset();
    }

    // We defer calling _.debounce until the first time it is used so that protractor running the system
    // tests has an opportunity to replace _.debounce in protractor.conf. See CRAB-7098
    if (!debouncedOnDurationChange) {
      debouncedOnDurationChange = _.debounce(onDurationChange, DEBOUNCE.MEDIUM);

      /**
       * Called when the display range changes so that data that depends on the display range is updated.
       */
      function onDurationChange() {
        // Reset capsule panel page to the first page before the table and chart capsules are fetched
        // The action is dispatched directly instead of calling the action method to avoid fetching capsules twice
        flux.dispatch('TREND_RESET_CAPSULE_PANEL_OFFSET', null, PUSH_IGNORE);

        if (sqTrendStore.isTrendViewCapsuleTime()) {
          return fetchTableAndChartCapsules();
        } else {
          return fetchAllItems({
            skipProps: true,
            skipTimebar: true,
          });
        }
      }
    }

    if (!isAutoUpdateInit) {
      debouncedOnDurationChange();
    }
  }
}

/**
 * Overwrites the display range and auto updating in the store. Does not cause the trend to refresh data because
 * this method was made for overwriting the display range via url parameters in the middle of a rehydration with
 * the expectation that fetchAllItems would be called at a later time.
 *
 * @param {String} start - New start time as iso string.
 * @param {String} end - New end time as iso string.
 */
export function displayRangeSetParamsInStore(start, end) {
  const startMoment = parseISODate(start);
  const endMoment = parseISODate(end);
  if (startMoment.isValid() && endMoment.isValid() && startMoment.isBefore(endMoment)) {
    flux.dispatch('UPDATE_DISPLAY_RANGE_TIMES', { start: startMoment, end: endMoment }, PUSH_IGNORE);
  }
}

/********************************************** Investigate Range **********************************************/

/**
 * Updates the investigate range start and end times. Duration is automatically adjusted to match the new range.
 * If the start time is after the end time, the values are rejected and no change is made.
 * @param {Number} start - New start time
 * @param {Number} end   - New end time
 * @param {String} [option] - sqStateSynchronizer option (e.g. PUSH_IGNORE) that modifies workstep push behavior.
 */
export function investigateRangeUpdateTimes(start, end, option?) {
  const startMoment = moment.utc(start);
  const endMoment = moment.utc(end);

  if (startMoment >= endMoment) {
    return;
  }

  // Auto updates should not create worksteps
  if (isAutoUpdate) {
    option = PUSH_IGNORE;
  }
  exposedForTesting.dispatchUpdateInvestigateRangeTimes(startMoment, endMoment, option);
  if (!isAutoUpdate) {
    autoUpdateComputeOffset();
  }
}

/**
 * Sets the investigate range with the start/end times from the display range
 */
export function investigateRangeCopyFromDisplayRange() {
  investigateRange.updateTimes(
    moment.utc(sqDurationStore.displayRange.start),
    moment.utc(sqDurationStore.displayRange.end),
  );
}

/**
 * Dispatch the investigate range update times change
 * @param {Moment} start - New start time as a Moment.
 * @param {Moment} end - New end time as a Moment.
 * @param {String} [option] - sqStateSynchronizer option (e.g. PUSH_IGNORE) that modifies workstep push behavior.
 * @param {Boolean} [skipDisplayCheck=false] - True to skip checking if the display range should be shrunk
 */
function dispatchUpdateInvestigateRangeTimes(start, end, option, skipDisplayCheck = false) {
  if (!start.isSame(sqDurationStore.investigateRange.start) || !end.isSame(sqDurationStore.investigateRange.end)) {
    flux.dispatch('UPDATE_INVESTIGATE_RANGE_TIMES', { start, end }, option);
    if (!skipDisplayCheck) {
      shrinkDisplayRangeIfRequired();
    }

    // We defer calling _.debounce until the first time it is used so that protractor running the system
    // tests has an opportunity to replace _.debounce in protractor.conf. See CRAB-7098
    if (!debouncedOnInvestigateChange) {
      debouncedOnInvestigateChange = _.debounce(onInvestigateChange, DEBOUNCE.MEDIUM);

      /**
       * Called when the investigate range changes so that data that depends on the investigate range is updated.
       */
      function onInvestigateChange() {
        handleInvestigateRangeUpdate();
        fetchAllTimebarCapsules();
      }
    }

    debouncedOnInvestigateChange();
  }
}

/**
 * Shifts the investigation range so that it encompasses the display range.
 */
export function shiftInvestigateRangeIfRequired() {
  const displayRange = sqDurationStore.displayRange;
  const investigateRange = sqDurationStore.investigateRange;
  const startDelta = investigateRange.start.valueOf() - displayRange.start.valueOf();
  const endDelta = investigateRange.end.valueOf() - displayRange.end.valueOf();

  if (investigateRange.duration < displayRange.duration) {
    // If the investigate range is smaller than the display range, shift so that the ends are the same.
    exposedForTesting.dispatchUpdateInvestigateRangeTimes(
      moment.utc(investigateRange.start.valueOf() - endDelta),
      moment.utc(investigateRange.end.valueOf() - endDelta),
      PUSH_IGNORE,
      true,
    );
  } else if (startDelta > 0 && endDelta > 0) {
    // If both deltas are positive, then we shift the investigate range to the left to align the starts.
    exposedForTesting.dispatchUpdateInvestigateRangeTimes(
      moment.utc(investigateRange.start.valueOf() - startDelta),
      moment.utc(investigateRange.end.valueOf() - startDelta),
      PUSH_IGNORE,
      true,
    );
  } else if (startDelta < 0 && endDelta < 0) {
    // If both deltas are negative, then we shift the investigate range to the right to align the ends.
    // This can't actually happen right now, since we keep the investigate range end constant when processing
    // a new investigate duration.
    exposedForTesting.dispatchUpdateInvestigateRangeTimes(
      moment.utc(investigateRange.start.valueOf() - endDelta),
      moment.utc(investigateRange.end.valueOf() - endDelta),
      PUSH_IGNORE,
      true,
    );
  }
}

/**
 * If required, expands the investigate range to encompass the display range
 */
export function expandInvestigateRangeIfRequired() {
  const displayRange = sqDurationStore.displayRange;
  const investigateRange = sqDurationStore.investigateRange;
  const newStart =
    displayRange.start < investigateRange.start || displayRange.duration > investigateRange.duration
      ? displayRange.start
      : investigateRange.start;
  const newEnd =
    displayRange.end > investigateRange.end || displayRange.duration > investigateRange.duration
      ? displayRange.end
      : investigateRange.end;
  exposedForTesting.dispatchUpdateInvestigateRangeTimes(newStart, newEnd, PUSH_IGNORE, true);
}

/**
 * If required, shrinks the display range to remain within the investigate range
 */
export function shrinkDisplayRangeIfRequired() {
  const displayRange = sqDurationStore.displayRange;
  const investigateRange = sqDurationStore.investigateRange;
  if (displayRange.end > investigateRange.end) {
    const newEnd = investigateRange.end;
    const newStart = moment.utc(
      Math.max(newEnd.valueOf() - displayRange.duration.asMilliseconds(), investigateRange.start.valueOf()),
    );
    dispatchUpdateDisplayRangeTimes(newStart, newEnd, false, PUSH_IGNORE, true);
  }

  if (displayRange.start < investigateRange.start) {
    const newStart = investigateRange.start;
    const newEnd = moment.utc(
      Math.min(newStart.valueOf() + displayRange.duration.asMilliseconds(), investigateRange.end.valueOf()),
    );
    dispatchUpdateDisplayRangeTimes(newStart, newEnd, false, PUSH_IGNORE, true);
  }
}

export const exposedForTesting = {
  dispatchUpdateInvestigateRangeTimes,
};
